.form-group {
	.row {
		align-items: center;
	}

	label {
		margin: 0;
	}

	.form-control {
		border-top: none;
		border-left: none;
		border-right: none;
	}

	.invalid-feedback {
		font-size: 1rem;
		color: $white;
	}

	input[type="number"] {
		-moz-appearance: textfield;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
}