header {
	@include clearfix();

	.header-content {
		background: linear-gradient(to bottom, rgba($osmo-light, .25) 0%, rgba($osmo-light, .25) 1%, rgba($osmo-light, .5) 100%);

		.logo {
			padding: 1.5rem;
		}
	}

	.header-bottom {
		width: 100%;
		height: 1rem;
		background: rgba($osmo-light, .5);
		margin-bottom: 20px;
		display: block;
		position: relative;
		border-radius: 0 0 50% 50% / 0 0 100% 100%;
	}
	.logo path {
		fill: #ffffff;
	}
}