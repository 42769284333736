html,
body {
	height: 100%
}

body {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	@if ($enable-background-image) {
		background-image: url("/assets/splash/Blurred/launch-1668x2224.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;

		.bg {
			z-index: -1;
			position: fixed;
			top: 0;
			left: 0;
			background-color: rgba($osmo-dark, .5);
			/* Full height */
			height: 100%;
			width: 100%;
			overflow: hidden;
		}
	}

}

main {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	.collapsing {
		transition: none;
		display: none;
	}
	.results-table {
		.row {
			margin-bottom: .25rem;
		}
	}
}
